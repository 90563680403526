<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/security/dbjiej_800.jpg">
                </div>
				<div class="con_top_right">
					<h2>等保合规安全解决方案</h2>
					<p class="new_tit">        向客户提供信息系统的定级备案、差距测评、整改加固、安全产品部署、合规复测等一站式的合规咨询和及建设服务，满足企业各类应用系统的等级保护合规需求。</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">在线咨询</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>全方位为客户提供等级保护的咨询服务、定级备案流程工作代办、专家评审工作对接、测评机构协调对接、技术整改服务、制度整改服务等全流程服务，包括通过远程或者现场访谈的方式，为客户量身定制等保解决方案。</p>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
				
				
            
        <!-- 为什么选择万方？万方有什么优势？ -->
                <div class="product">
                    <h4>产品简述</h4>
                    <div class="product_content">
                        <p>       向客户提供信息系统的定级备案、差距测评、整改加固、安全产品部署、合规复测等一站式的合规咨询和及建设服务，满足企业各类应用系统的等级保护合规需求。</p>
                    </div>
                </div>

                <div class="introduction">
                    <h4>等级保护简介</h4>
                    <div class="introduction_content">
                        <div class="floot">
                            <div class="floot_left">
                                <p>      向客户提供信息系统的定级备案、差距测评、整改加固、安全产品部署、合规复测等一站式的合规咨询和及建设服务，满足企业各类应用系统的等级保护合规需求。</p>
                            </div>
                            <div class="floot_right">
                                <img src="../../assets/images/security/01.jpg" alt="">
                            </div>
                        </div>
                        <div class="floot_img">
                            <img src="../../assets/images/security/02.jpg" alt="">
                        </div>
                    </div>
                </div>

                <div class="divide">
                    <h4>等保定级划分</h4>
                    <div class="divide_content">
                        <p>定级分化</p>
                        <div class="divide_img">
                            <img src="../../assets/images/security/03.jpg" alt="">
                        </div>
                        <ul class="divide_ul">
                            <li>第一级：用户自主保护。无需备案，对测评周期无要求</li>
                            <li>第二级：指导保护级。公安部门备案，建议两年测评一次</li>
                            <li>第三级：监督保护级。公安部门备案，要求每年测评一次</li>
                            <li>第四级：强制保护级。公安部门备案，要求半年一次</li>
                            <li>第五级：强制保护级。公安部门备案，依据特殊安全需求进行</li>
                        </ul>
                    </div>
                </div>

                <div class="industry">
                    <h4>哪些行业涉及办理等级保护</h4>
                    <div class="industry_content">
                        <img src="../../assets/images/security/04.jpg" alt="">
                    </div>
                </div>

                <div class="develop">
                    <h4>业务开展的必要性</h4>
                    <div class="develop_content">
                        <ul>
                            <li>
                                <div class="li_img">
                                    <img src="../../assets/images/security/07.jpg" alt="">
                                </div>
                                <div class="li_right">
                                    <h5>1.法律法规要求：</h5>
                                    <p>《网络安全法》明确规定信息系统运营、使用单位应当按照网络安全等级保护制度要求，履行安全保护义务，如拒不履行，将会受到相应处罚。</p>
                                </div>
                            </li>
                            <li>
                                <div class="li_img">
                                    <img src="../../assets/images/security/06.jpg" alt="">
                                </div>
                                <div class="li_right">
                                    <h5>2.行业准入要求：</h5>
                                    <p>在金融、电力、广电、医疗、教育行业，主管单位明确要求企业开展等级保护工作。</p>
                                </div>
                            </li>
                            <li>
                                <div class="li_img">
                                    <img src="../../assets/images/security/05.jpg" alt="">
                                </div>
                                <div class="li_right">
                                    <h5>3.企业系统安全需求：</h5>
                                    <p>开展等级保护工作可以发现企业本身信息系统存在的安全隐患和不足，通过安全整改提升信息系统的信息安全防护能力。</p>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                </div>

                <div class="profit">
                    <h4>客户收益</h4>
                <div class="profit_content">
                    <ul>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/security/08.jpg" alt="">
                            </div>
                            <p>满足合规性需求</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/security/011.jpg" alt="">
                            </div>
                            <p>了解业务安全边界，提升业务系统安全和网络防护能力</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/security/010.jpg" alt="">
                            </div>
                            <p>保护企业核心信息及客户信息</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/security/09.jpg" alt="">
                            </div>
                            <p>建立完善的网络安<br>全管理制度，长制久安</p>
                        </li>
                        <li>
                            <div class="lis_img">
                                <img src="../../assets/images/security/012.jpg" alt="">
                            </div>
                            <p>有效提升公信度，增强公共网络和信息系<br>统的可信度，相比未过等保的，更有竞争力</p>
                        </li>
                    </ul>
                </div>
              </div>

              <div class="detailed">
                  <h4>产品详细</h4>
                  <div class="detailed_content">
                      <h5>1.等保服务流程</h5>
                      <div class="detailed_img">
                          <img src="../../assets/images/security/013.jpg" alt="">
                      </div>
                      <h5>2.等级保护流程中各角色</h5>
                      <div class="detailed_img">
                          <img src="../../assets/images/security/014.jpg" alt="">
                      </div>
                  </div>
              </div>

              <div class="advantage">
                   
                        <h4>我们的优势</h4>
                    
                    <div class="advantage_content">
                        <div class="vant">
                            <div class="vant_left">
                                <ul>
                                    <li>1.国家信息安全服务资质(安全工程类)</li>
                                    <li>2.国家信息安全风险评估资质</li>
                                    <li>3.国家通信网络安全服务风险评估资质</li>
                                    <li>4.国家信息安全应急处理服务资质</li>
                                    <li>5.国家信息系统安全集成服务资质</li>
                                    <li>6.国家信息安全漏洞库支持单位</li>
                                    <li>7.国际信息安全管理体系ISO 27001认证</li>
                                    <li>8.国际质量管理体系ISO9001认证</li>
                                    <li>9.国际环境管理体系ISO 14001认证</li>
                                    <li>10.高新技术企业</li>
                                    <li>11.软件企业</li>
                                    <li>12.企业研究开发机构证书</li>
                                </ul>
                            </div>
                            <div class="vant_right">
                                <img src="../../assets/images/dbhg/zz.jpg" alt="">
                            </div>
                        </div>
                        <div class="age">
                            <ul>
                                <li>1.安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</li>
                                <li>2.高效的等保通过率，帮助客户在最短的时间最高的质量最低的价格通过等保测评认证。</li>
                                <li>3.稳定的合作关系：与众多测评机构建立稳定的合作关系，深刻理解测评重点，帮助客户省时省力通过等保合规。</li>
                                <li>4.公司资质：具备国家网络信息安全主管部门颁发的权威安全认证资质及国际权威认证资质。</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="case">
                    
                    <h4>典型案例</h4>
                    
                    <div class="case_content">
                        <ul>
                            <li>
                                <p style="color:#244CA3">1.广州某某智能物流公司</p>
                                <p>需求：该公司货运平台系统等级保护测评项目(三级)</p>
                                <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，省去大部分过度设备投资，用最小最优的成本实现等保过保。</p>
                            </li>
                            <li>
                                <p style="color:#244CA3">2.深圳市某某中学 </p>
                                <p>需求：等保二级</p>
                                <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，顺利通过等级保护测评机构的测评验收工作，取得公安部颁发的相应等保证书。</p>
                            </li>
                            <li>
                                <p style="color:#244CA3">3.杭州某某某网络科技有限公司</p>
                                <p>需求：某某某出行系统等级保护测评项目（三级）</p>
                                <p>在我司专业服务下，对其系统进行了从定级备案到提交测评报告的全程7个步骤咨询服务，并根据我司专业建议，在安全整改时，根据实际需求和合理安排，省去大部分过度设备投资，用最小最优的成本取得三级等级保护证书。</p>
                            </li>
                        </ul>
                    </div>
                </div>
			


				
 
			</div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/record/guangdong.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东省增值电信业务...</p>
                                        <p>1、服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/assessment">
                                            <img src="@/assets/images/app/008.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>安全评估报告（全国互联网安全管理服务平台要求提交的报告）</p>
                                        <p>App、安全评估报告...</p>
                                        <p>￥9800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}

.product{
    margin-top: 80px;
    h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .product_content{
        margin-top: 80px;
        padding: 0 35px;
        p{
            font-size: 20px;
            line-height: 30px;
            color: #244CA3;
            text-indent: 2em;
        }
    }
}

.introduction{
    margin-top: 66px;
     h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .introduction_content{
        margin-top: 10px;
        .floot{
            display: flex;
            justify-content: center;
            .floot_left{
                width: 491px;
                height: 293px;
              
                background-color: #244CA3;
                p{
                    font-size: 20px;
                    line-height: 24px;
                    text-indent: 2em;
                    color: white;
                    margin: 0 30px;
                    margin-top: calc(200px/2);
                }
            }
            .floot_right{
                width: 370px;
                height: 293px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .floot_img{
            text-align: center;
            margin-top: 20px;
        }
    }
}

.divide{
    margin-top: 40px;
     h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .divide_content{
        margin-top: 24px;
        p{
            padding: 0 20px;
            font-size: 22px;
            color: #244CA3;
        }
        .divide_img{
            width: 850px;
            height: 280px;
            text-align: center;
            margin-top: 42px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .divide_ul{
            margin-top: 61px;
            padding: 0 100px;
            li{
                font-size: 20px;
                color: #244CA3;
                margin-bottom: 12px;
            }
        }
    }
}

.industry{
    margin-top:71px;
     h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .industry_content{
        text-align: center;
        margin-top: 63px;
    }
}

.develop{
     margin-top:68px;
     h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .develop_content{
        margin-top: 137px;
        ul{
            li{
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-bottom: 35px;
                .li_img{
                    width: 93px;
                    height: 91px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .li_right{
                    width: 552px;
                    h5{
                        font-size: 22px;
                        color: #244CA3;
                    }
                    p{
                        font-size: 20px;
                        line-break: 24px;
                    }
                }
            }
        }
    }
}

.profit{
     margin-top: 77px;
     h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .profit_content{
        margin-top: 70px;
        ul{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: center;
            li{
                display: flex;
                flex-direction: column;
                align-items: center;
                 margin-bottom: 65px;
                .lis_img{
                    width: 93px;
                    height: 91px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p{
                    font-size: 22px;
                    line-height: 24px;
                    width: 280px;
                    text-align: center;
                    margin-top: 30px;
                }
            }
            :nth-child(3){
               
                p{
                    width: 154px;
                }
            }
            :nth-child(4){
                p{
                    width: 353px;
                }
            }
            :nth-child(5){
                p{
                    width: 430px;
                }
            }
        }
    }
}

.detailed{
     margin-top: 50px;
     h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .detailed_content{
        margin-top: 30px;
        padding: 10px;
        h5{
            margin-top:20px;
            font-size: 22px;
            color: #244CA3;
        }
        .detailed_img{
            width: 860px;
            margin-top: 20px;
            img{
                width: 100%;
            }
        }
    }
}

.advantage{
     margin-top: 77px;
     h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .advantage_content{
        margin: 0 30px;
        .vant{
            margin-top: 30px;
           
            display: flex;
            justify-content: space-around;
            align-items: center;
            .vant_left{
                ul{
                    li{
                        font-size: 22px;
                        margin-bottom: 10px;
                    }
                }
            }
            .vant_right{
                width: 380px;
                height: 340px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .age{
            width: 780px;
            height: 214px;
            background-color: #244CA3;
            padding: 20px 20px;
            ul{
                li{
                    font-size: 22px;
                    color: white;
                    line-height: 30px;
                }
            }
        }
    }
}

.case{
     margin-top: 77px;
   h4{
        font-size: 29px;
        color: #244CA3;
        text-align: center;
        font-weight: bold;
    }
    .case_content{
        padding: 30px;
        margin-top: 30px;
        ul{
            li{
                margin-bottom: 30px;
                p{
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }
}




.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
	margin-left:8px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #244CA3;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #244CA3;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 25px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#244CA3;
    text-indent: 2em;
	line-height: 24px;
	font-size:16px;
    padding:10px 0;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 35px;
    padding-right: 2px;
    font-weight: bold;
}
.pri_box  .price{
	font-size: 26px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
       p{
           font-size: 14px;
           line-height: 30px;
       }
    }
}
.box_ti{
   margin-top:30px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







